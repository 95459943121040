import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { IOpenOptionFilter } from '../../store/features/filter/FilterSlice'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import {
  GetDealFilterDataResult_getDealFilterData,
  GetDealFilterDataResult_getDealFilterData_companyStatuses,
} from '../../__generated__/GetDealFilterDataResult'
import { ITreeNode } from '../CheckboxTree/CheckboxTree'
import FCTDropdownListItem from '../DropdownListItem/FCTDropdownListItem'
import { AccordionItem } from '../Filters/AccordionItem'
import { ListCheckBoxFilter } from '../Filters/ListCheckBoxFilter'
import FCTSlider, { IRealValue } from '../Slider/FCTSlider'
import Taxonomy from '../Taxonomy/Taxonomy'
import FemaleFounderPicker from './share/FemaleFounderPicker'
import YearRangePicker from './share/YearRangePicker'

const useStyles = makeStyles(theme => ({
  flex: {
    flex: 1,
  },
  line: {
    width: 8,
    height: 1,
    backgroundColor: '#CBD5E1',
    margin: '0 6px',
  },
  mediumMargin: {
    marginBottom: 18,
  },
  smallMargin: {
    marginBottom: 16,
  },
  switchLabel: {
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey['900'],
  },
  selectText: {
    fontSize: 14,
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxText: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.grey['800'],
    marginRight: 15,
  },
  labelRadio: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  radio: {
    padding: 7,
  },
  group: {
    borderBottom: '1px solid #E7ECF3',
  },
  smallMarginLeft: {
    marginLeft: 2,
  },
  typoFontSize: {
    fontSize: 12,
  },
}))

const OverviewItem = (props: IOperationItemProps) => {
  const classes = useStyles()
  const {
    filter,
    setFilter,
    handleChange,
    getFilterData,
    enableNodesAnalysis,
    expandedNodesAnalysis,
    companyStatusesData,
    openOption,
    setOpenOption,
    customStyle,
  } = props

  return (
    <>
      <Taxonomy
        filter={filter}
        setFilter={setFilter}
        getFilterData={getFilterData}
        openOption={openOption}
        setOpenOption={setOpenOption}
        customStyle={customStyle}
        enableNodesAnalysis={enableNodesAnalysis}
        expandedNodesAnalysis={expandedNodesAnalysis}
      />

      <div className={classes.group}>
        <AccordionItem
          title="Company Overview"
          open={openOption?.companyOverview}
          setOpen={value => setOpenOption && setOpenOption(value, 'companyOverview')}
          customStyle={customStyle}
        >
          <OverviewChildItem
            filter={filter}
            setFilter={setFilter}
            handleChange={handleChange}
            companyStatusesData={companyStatusesData}
          />
        </AccordionItem>
      </div>
    </>
  )
}

export default OverviewItem

interface IOperationItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  handleChange(e: React.SyntheticEvent): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  enableNodesAnalysis?: ITreeNode[]
  expandedNodesAnalysis?: string[]
  companyStatusesData?: GetDealFilterDataResult_getDealFilterData_companyStatuses[] | null
  openOption?: IOpenOptionFilter
  setOpenOption?: (value: boolean, tag: string) => void
  customStyle?: { title?: React.CSSProperties }
}

interface IOverviewChildItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  handleChange(e: React.SyntheticEvent): void
  companyStatusesData?: GetDealFilterDataResult_getDealFilterData_companyStatuses[] | null
}

export const OverviewChildItem = (props: IOverviewChildItemProps) => {
  const { filter, setFilter, handleChange, companyStatusesData } = props

  return (
    <>
      <FCTDropdownListItem
        isOpen={true}
        title="Company Status"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <ListCheckBoxFilter
          nameField="companyStatuses"
          data={companyStatusesData || []}
          filterIds={filter.companyStatuses || []}
          setFilterData={setFilter}
        />
      </FCTDropdownListItem>
      <FCTDropdownListItem
        isOpen={true}
        title="Number of Employees"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <FCTSlider
          defaultRealValue={filter.numOfEmployeesRanges}
          dataMarks={['1', '10', '50', '100', '250', '500', '1000', '5000', '10000', '10001+']}
          onChangeRealValue={(v: IRealValue) => setFilter({ numOfEmployeesRanges: v })}
        />
      </FCTDropdownListItem>
      {/* TODO: remove in ticket EFDP-1680
          <FCTDropdownListItem
            isOpen={true}
            title={`Gender Diversity`}
            size="small"
            openedIcon="down"
            closedIcon="up"
          >
            <FemaleFounderPicker
              filter={filter}
              setFilter={setFilter}
              getFilterData={getFilterData}
            />
          </FCTDropdownListItem> */}
      <FCTDropdownListItem
        isOpen={true}
        title="Founded Year"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <YearRangePicker
          filter={filter}
          setFilter={setFilter}
          tagYearFrom="foundedYearFrom"
          tagYearTo="foundedYearTo"
          isYear="yearFoundedRange"
          handleChange={handleChange}
        />
      </FCTDropdownListItem>
    </>
  )
}
