import { ApolloProvider } from '@apollo/client'
import { Container, makeStyles } from '@material-ui/core'
import { lazy, Suspense } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import componentLoader from 'utils/componentLoader'
import Loading from '../components/Loading'
import { WatchListProvider } from '../features/watch-list/WatchListProvider'
import { useApolloClient } from '../store/ApolloClient'
import MainTemplate from '../templates/MainTemplate'
import Navbar from '../templates/Navbar'
import theme from '../theme'
import { isClient, isStartup, IUser } from '../utils/auth'
import GlobalRouterWrapper from './GlobalRouterWrapper'
import { useUserContext } from './InitUserWrapper'

const DashboardContainer = lazy(() => componentLoader(() => import('../pages/DashboardPage')))
const ComparisonPage = lazy(() => componentLoader(() => import('../pages/ComparisonPage')))
const CompanyProfilePage = lazy(() => componentLoader(() => import('../pages/CompanyProfilePage')))
const Analysis = lazy(() => componentLoader(() => import('../pages/AnalysisPage')))
const ExploreDatabasePage = lazy(() =>
  componentLoader(() => import('../pages/ExploreDatabasePage'))
)
const ReportsPage = lazy(() => componentLoader(() => import('../pages/ReportsPage')))
const NewsPage = lazy(() => componentLoader(() => import('../pages/NewsPage')))
const WatchListsPage = lazy(() => componentLoader(() => import('../pages/WatchListsPage')))
const NotificationPage = lazy(() => componentLoader(() => import('../pages/NotificationPage')))
const InvestorProfilePage = lazy(() =>
  componentLoader(() => import('../pages/InvestorProfilePage'))
)
const AdvancedSearchPage = lazy(() => componentLoader(() => import('../pages/AdvancedSearchPage')))

const useStyles = makeStyles({
  content: {
    flex: 1,
    display: 'flex',
    overflow: 'auto',
    height: 'calc(100vh - 60px)',
    marginLeft: '50px',
    marginRight: 8,
    paddingRight: 16,
    '&::-webkit-scrollbar': {
      width: 6,
      height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  root: {
    display: 'flex',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#f8fafc',
  },
})

export default () => {
  const classes = useStyles()
  let match = useRouteMatch()
  const { client } = useApolloClient()
  const { user, restricted, isAdmin } = useUserContext()
  const renderApp = () => {
    if (isStartup(user)) {
      return (
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path={`${match.path}/profile/:id`} component={CompanyProfilePage} />
            <Route path="*">
              <Redirect to={`${match.path}/profile/${user.profile.companyId}`} />
            </Route>
          </Switch>
        </Suspense>
      )
    } else if (isClient(user)) {
      return (
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={`${match.path}`}>
              <Redirect to={`${match.path}/dashboard`} />
            </Route>
          </Switch>
          <Switch>
            <Route path={`${match.path}/reports`} component={ReportsPage} />
            <Route path={`${match.path}/news`} component={NewsPage} />
            <Route path={`${match.path}/dashboard`} component={DashboardContainer} />
            <Route path={`${match.path}/comparison`} component={ComparisonPage} />
            <Route path={`${match.path}/profile/:id`} component={CompanyProfilePage} />
            <Route path={`${match.path}/investor-profile/:id`} component={InvestorProfilePage} />
            <Route path={`${match.path}/analysis`} component={Analysis} />
            <Route path={`${match.path}/list-view`} component={ExploreDatabasePage} />
            <Route path={`${match.path}/smart-search`} component={AdvancedSearchPage} />
            {!restricted.watchlist && (
              <Route path={`${match.path}/watch-lists`} component={WatchListsPage} />
            )}
            {!restricted.notifications && (
              <Route path={`${match.path}/notifications`} component={NotificationPage} />
            )}
            <Route render={() => <Redirect to={{ pathname: `${match.path}/dashboard` }} />} />
          </Switch>
        </Suspense>
      )
    } else {
      return <></>
    }
  }

  if (!client) return null

  return (
    <ApolloProvider client={client}>
      <GlobalRouterWrapper>
        <WatchListProvider>
          <MainTemplate
            disabledHeader={{
              watchlist: restricted.watchlist,
              search: restricted.search,
              requestCompany: restricted.requestCompany,
            }}
          >
            <Navbar
              disabled={restricted.navbar}
              restricted={{ notifications: restricted.notifications }}
              isAdmin={isAdmin}
            />
            <Container className={classes.content} id="main-content" maxWidth="xl">
              {renderApp()}
            </Container>
          </MainTemplate>
        </WatchListProvider>
      </GlobalRouterWrapper>
    </ApolloProvider>
  )
}
