import { useQuery } from '@apollo/client'
import { Box, Chip, ChipProps, makeStyles, useTheme, withStyles } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import clsx from 'clsx'
import FCTIcon from 'components/Icon/Icon'
import { EXPAND_ROUND1_ID } from 'core/constants/companyFilter'
import useWindowSize from 'core/hooks/useWindowSize'
import { concat, flatMapDeep, find, findKey, uniqBy, xor } from 'lodash'
import moment from 'moment'
import React, {
  Fragment,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { isObject } from 'routers/QueryParams'
import { FCT_SCORE_OVERALL_KEY } from 'store/cache/CompaniesList'
import { IAnalysisFilter } from 'types/Filter'
import { ITreeData } from 'types/Tree'
import { getMembers } from 'utils/chips'
import { LIST_CHIP_ORDERS, MIN_SMART_CLUSTER_DISTANCE, SPECIAL_CHIPS } from 'utils/consts'
import { formatAbbreviation, formatNumber } from 'utils/convert/number'
import { COMPARE_TYPE } from 'utils/convert/Title'
import { ETagGroupsType, tagGroupsMapData } from 'utils/Taxonomy'
import { TaxonomyFilterMapping, getFlatArrayFromTree } from 'utils/TaxonomyMapping'
import { getChildrenIds } from 'utils/Tree'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '__generated__/globalTypes'
import {
  useAnalysisFilter,
  useBreadcrumbs,
  useChipsHeader,
} from '../store/features/analysis/AnalysisHook'
import { ChipItem, initialAnalysisFilter } from '../store/features/analysis/AnalysisSlice'
import { useCompanyDealFilter, useSubFilter } from '../store/features/company-deal/CompanyDealHook'
import {
  initCompanyDealFilter,
  initSubFilter,
  initSmartCluster,
} from '../store/features/company-deal/CompanyDealSlice'
import {
  useWatchListFilter,
  useWatchListId,
  useWatchListName,
  useWatchListType,
} from '../store/features/watch-list/WatchListHook'
import { GET_DEAL_FILTER_DATA } from '../store/operations/queries/GetDealFilterData'
import { GET_INVESTORS_FILTER_DATA } from '../store/operations/queries/GetInvestorsFilterData'
import { ICompanyDealFilter } from '../types/CompanyDealFilter'
import { CATEGORY_TAXONOMY, useResetFilter } from '../utils/CompanyFilter'
import { tagGroupsMapDataFilter } from '../utils/Filter'
import {
  GetDealFilterDataResult_getDealFilterData,
  GetDealFilterDataResult_getDealFilterData_products,
} from '../__generated__/GetDealFilterDataResult'
import { GetInvestorsFilterData } from '../__generated__/GetInvestorsFilterData'
import { IdName } from './AnalysisChipItem/AnalysisChipItem'
import FCTButton from './Button/FCTButton'
import Flex from './Flex/Flex'
import { default as Icon } from './Icon/Icon'
import FCTTooltip from './Tooltip/Tooltip'
import { EWatchListType } from './WatchList/SelectTabWatchList'

const FORMAT_DATE = 'DD/MM/YYYY'
const DEFAULT_DATA_MAX = 0

const StyledChip = withStyles({
  root: {
    zIndex: 1,
    borderRadius: '3px',
  },
  label: {
    textTransform: 'capitalize',
    paddingLeft: 14,
    paddingRight: 8,
  },
  deleteIcon: {
    width: 12,
    height: 12,
    opacity: 0,
    marginRight: '2px !important',
  },
})(Chip)

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: 'auto',
    padding: '0 14px',
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    backgroundColor: '#FFFFFF',
    height: '32px',
    borderRadius: '16px',
    boxSizing: 'border-box',

    '&.active': {
      backgroundColor: theme.palette.primary.light,
      color: '#FFFFFF',
      fontWeight: 600,
    },
  },
  hideButton: {
    color: theme.palette.primary.main,
    lineHeight: '32px',
    fontSize: '12px',
    cursor: 'pointer',
  },
  missingChip: {
    padding: '12px',
    maxWidth: '660px',
    display: 'flex',
    flexFlow: 'row wrap',
  },
  moreText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 250,
  },
  chipList: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    overflow: 'hidden',
  },
  buttonReset: {
    minWidth: 'auto',
    padding: '0 14px',
    color: '#FF9F19',
    border: 'none',
    margin: 0,
    height: '32px',
    borderRadius: '16px',
    boxSizing: 'border-box',
  },
  buttonIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapperChip: {
    padding: '6px 15px 6px 0px',
    height: 32,
  },
}))

const HAS_IPO_KEY = 'hasIPO'
const HAS_IPO_LABEL = 'Has IPO'
const HAS_ACQUIRED_LABEL = 'Has been acquired'
const HAS_ACQUIRED_KEY = 'hasBeenAcquired'

export const CHIP_HIDE_LISTVIEW: Record<keyof ICompanyDealFilter, boolean> = {
  modeName: true,
  category: true,
  businessLines: true,
  risks: false,
  valueChains: true,
  products: true,
  productParentId: false,
  regions: true,
  subRegions: true,
  subRegion2s: true,
  countries: true,
  businessModelIds: true,
  companyStatuses: true,
  numOfEmployeesRanges: true,
  switchFemalePicker: false,
  numOfFemaleFounderFrom: true,
  numOfFemaleFounderTo: true,
  numOfFemaleFounderPercentFrom: true,
  numOfFemaleFounderPercentTo: true,
  yearFoundedRange: false,
  foundedYearFrom: true,
  foundedYearTo: true,
  latestFundingRound1s: true,
  latestFundingRound2s: true,
  fundingRound1s: true,
  fundingRound2s: true,
  totalFundingFrom: true,
  totalFundingTo: true,
  equityFundingFrom: true,
  equityFundingTo: true,
  latestDealAmountFrom: true,
  latestDealAmountTo: true,
  dealAmountFrom: true,
  dealAmountTo: true,
  yearFundingRange: false,
  fundingDateFrom: true,
  fundingDateTo: true,
  investors: true,
  investorsTypes: true,
  leadInvestors: true,
  leadInvestorTypes: true,
  numberOfInvestorsRanges: true,
  numberOfFundingRounds: true,
  acquirerTypes: true,
  acquirers: true,
  acquisitionYearRange: false,
  acquiredDateFrom: true,
  acquiredDateTo: true,
  orderByCompany: false,
  orderByDeal: false,
  searchData: false,
  selectedBusinessLineId: false,
  selectedProductId: false,
  viewBy: false,
  tagGroupIds: true,
  includedEntities: false,
  wentPublicOnDateFrom: true,
  wentPublicOnDateTo: true,
  smartCluster: false,
  distributionModelIds: true,
  hasBeenAcquired: true,
  hasIPO: true,
  operatingModels: true,
}

const CHIP_TYPE_DATE = [
  'fundingDateFrom',
  'fundingDateTo',
  'acquiredDateFrom',
  'acquiredDateTo',
  'wentPublicOnDateFrom',
  'wentPublicOnDateTo',
]

const CHIP_TYPE_YEAR = ['foundedYearTo', 'foundedYearFrom']

const listKeyFromAndTo = [
  { keyFrom: 'fundingDateFrom', keyTo: 'fundingDateTo' },
  { keyFrom: 'acquiredDateFrom', keyTo: 'acquiredDateTo' },
  { keyFrom: 'wentPublicOnDateFrom', keyTo: 'wentPublicOnDateTo' },

  { keyFrom: 'numOfFemaleFounderFrom', keyTo: 'numOfFemaleFounderTo' },
  { keyFrom: 'numOfFemaleFounderPercentFrom', keyTo: 'numOfFemaleFounderPercentTo' },
  { keyFrom: 'totalFundingFrom', keyTo: 'totalFundingTo' },
  { keyFrom: 'equityFundingFrom', keyTo: 'equityFundingTo' },
  { keyFrom: 'latestDealAmountFrom', keyTo: 'latestDealAmountTo' },
  { keyFrom: 'dealAmountFrom', keyTo: 'dealAmountTo' },
  { keyFrom: 'foundedYearFrom', keyTo: 'foundedYearTo' },

  { keyFrom: 'numOfEmployeesRanges', keyTo: '' },
  { keyFrom: 'numberOfInvestorsRanges', keyTo: '' },
  { keyFrom: 'numberOfFundingRounds', keyTo: '' },
]

const listKeyWithStartAndEnd = [
  'numOfEmployeesRanges',
  'numberOfInvestorsRanges',
  'numberOfFundingRounds',
]

export enum TitleTooltip {
  regions = 'HQ Region',
  subRegions = 'HQ Sub-Region 1',
  subRegion2s = 'HQ Sub-Region 2',
  countries = 'HQ Country',
  businessModelIds = 'Business Model',
  fundingRound1s = 'All Deal Type',
  fundingRound2s = 'All Deal Type',
  companyStatuses = 'Company Status',
  latestFundingRound1s = 'Latest Deal Type',
  latestFundingRound2s = 'Latest Deal Type',
  investorsTypes = 'Investor Type',
  leadInvestorTypes = 'Lead Investor Type',
  acquirerTypes = 'Acquirer Type',
  acquirers = 'Acquirer',
  investors = 'Investor',
  leadInvestors = 'Lead Investor',
  tagGroupIds = 'Tag',
  includedEntities = 'Included Entities',

  products = 'Products',
  businessLines = 'Business Lines',
  valueChains = 'Value Chains',

  wentPublicOnDateFrom = 'IPO Date',
  acquiredDateFrom = 'Acquired Date',
  fundingDateFrom = 'Funding Date',

  numOfFemaleFounderFrom = 'numOfFemaleFounderTo',
  numOfFemaleFounderPercentFrom = 'numOfFemaleFounderPercentTo',
  totalFundingFrom = 'Total Funding',
  equityFundingFrom = 'Equity Funding',
  latestDealAmountFrom = 'Latest Deal Amount',
  dealAmountFrom = 'All Deal Amount',
  foundedYearFrom = 'Founded Year',

  numOfEmployeesRanges = 'Number of Employees',
  numberOfInvestorsRanges = 'Number of Investors',
  numberOfFundingRounds = 'Number of Funding Rounds',
  modeName = 'Smart Cluster',

  selectedProductId = 'Products',
  category = 'Industry',
  selectedBusinessLineId = 'Business Line',
  distributionModelIds = 'Distribution Model',

  hasBeenAcquired = 'Acquired',
  operatingModels = 'Operating Models',
  hasIPO = 'IPO',
}

export const StyledChipItem = (
  props: ChipProps & { onDelete(): void; title: string; hasOnDelete: boolean; filter: any }
) => {
  const theme = useTheme()
  const [isHover, setIsHover] = React.useState(false)

  const IconClone = React.cloneElement(props.deleteIcon || <Icon name={'close1'} />, {
    style: {
      color: theme.palette.grey[500],
      opacity: isHover && props.hasOnDelete ? 0.7 : 0,
      display: props.hasOnDelete ? 'unset' : 'none',
    },
  })
  return (
    <>
      <FCTTooltip
        title={<span>{props.title || ''}</span>}
        arrow
        placement="top"
        disableFocusListener={props.disabled}
        disableHoverListener={props.disabled}
        disableTouchListener={props.disabled}
      >
        <Box onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
          <StyledChip
            variant="outlined"
            {...props}
            style={{
              color: theme.palette.grey[500],
              borderColor: theme.palette.grey[400],
              backgroundColor: '#FFFFFF',
              ...props.style,
            }}
            deleteIcon={IconClone}
            onDelete={props.onDelete}
          />
        </Box>
      </FCTTooltip>
    </>
  )
}

interface ChipsHeaderProps {
  filter: any
  filterData: GetDealFilterDataResult_getDealFilterData
  setFilter: (value: any) => void
  hasButtonReset?: boolean
}

const initIdsNotShows = {
  products: [],
  tagGroupIds: [],
  valueChains: [],
  fundingRound2s: [],
  latestFundingRound2s: [],
}

type IdsNotShowsType = {
  products: Array<number>
  tagGroupIds: Array<number>
  valueChains: Array<number>
  fundingRound2s: Array<number>
  latestFundingRound2s: Array<number>
}

const ChipsHeader = (props: ChipsHeaderProps) => {
  const classes = useStyles()
  const { filterData, filter, setFilter, hasButtonReset } = props
  const [chips, setChipsHeader] = useChipsHeader()
  const [breadcrumbsData, setBreadcrumbsData] = useBreadcrumbs()
  const [watchListName, setWatchListName] = useWatchListName()
  const [watchListId, setWatchListId] = useWatchListId()
  const [watchlistType, _] = useWatchListType()
  const [watchlistFilter, __] = useWatchListFilter()
  const [productsData, setProductsData] = useState<
    GetDealFilterDataResult_getDealFilterData_products[]
  >([])
  const history = useHistory()
  const [idsNotShows, setIdsNotShow] = useState<IdsNotShowsType>(initIdsNotShows)

  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA, {
    onCompleted() {
      setProductsData(getFilterData?.getDealFilterData?.data?.products || [])
    },
  })

  const companyFilterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData
  const [filterAnalysis, setAnalysisFilter] = useAnalysisFilter()
  const [companyFilter, setCompanyFilter] = useCompanyDealFilter()
  const [subFilter, setSubFilter] = useSubFilter()

  const filteredProducts = useMemo(() => {
    return productsData.filter(product => product.category == filter.category) || []
  }, [productsData, filter])

  const getTreeIndustry = useCallback(
    (id: number, name: string) => {
      const products = productsData.filter(product => product.category == CATEGORY_TAXONOMY.get(id))
      return {
        id: id,
        name: name,
        children: TaxonomyFilterMapping(products || []).map(v => ({
          ...v,
          parentId: id,
        })),
        parentId: id,
      }
    },
    [productsData]
  )

  const productsTree = useMemo(() => {
    if (filter.category == InternalDbCompanyTypes.all) {
      return concat(
        getTreeIndustry(TAXONOMY_ID.FINTECH_ID, 'FinTech'),
        getTreeIndustry(TAXONOMY_ID.REGTECH_ID, 'RegTech'),
        getTreeIndustry(TAXONOMY_ID.INSURANCE_ID, 'InsurTech')
      )
    }
    return TaxonomyFilterMapping(filteredProducts)
  }, [filteredProducts])

  const { data: acquirers } = useQuery<GetInvestorsFilterData>(GET_INVESTORS_FILTER_DATA, {
    variables: {
      input: { search: '', types: [], isAcquirer: true },
    },
  })

  const { data: investors } = useQuery<GetInvestorsFilterData>(GET_INVESTORS_FILTER_DATA, {
    variables: {
      input: { search: '', types: [], isAcquirer: false },
    },
  })

  const data = React.useMemo(
    () =>
      filterData
        ? {
            ...filterData,
            acquirers: acquirers?.getInvestorsFilterData || [],
            investors: investors?.getInvestorsFilterData || [],
            tagGroups: tagGroupsMapDataFilter(filterData?.tags || []),
            selectedProductId: filterData.products,
            selectedBusinessLineId: [
              ...(filterData.valueChains || []),
              ...(filterData.businessLines || []),
            ],
          }
        : null,
    [filterData, acquirers, investors]
  )

  const maxNumOfFundingRounds =
    String(companyFilterData?.maxNumOfFundingRounds || DEFAULT_DATA_MAX) || DEFAULT_DATA_MAX
  const maxNumOfInvestors =
    String(companyFilterData?.maxNumOfInvestors || DEFAULT_DATA_MAX) || DEFAULT_DATA_MAX

  const checkValueDefaultRangeObject = useCallback(
    (start: string, end: string, key: string) => {
      if (
        key === 'numOfEmployeesRanges' &&
        start === initialAnalysisFilter['numOfEmployeesRanges'].start &&
        (end === initialAnalysisFilter['numOfEmployeesRanges'].end ||
          end?.trim() ===
            initialAnalysisFilter['numOfEmployeesRanges']?.end?.toString()?.slice(0, -1))
      )
        return true
      if (
        key === 'numberOfFundingRounds' &&
        start === initialAnalysisFilter['numberOfFundingRounds'].start &&
        end === maxNumOfFundingRounds
      )
        return true
      if (
        key === 'numberOfInvestorsRanges' &&
        start === initialAnalysisFilter['numberOfInvestorsRanges'].start &&
        end === maxNumOfInvestors
      )
        return true
      if (!+start && !+end) return true
      return false
    },
    [companyFilterData]
  )

  const checkMaxValueOfListKeyWithStartAndEnd = (key: string, end: string) => {
    if (key === 'numOfEmployeesRanges' && end === initialAnalysisFilter['numOfEmployeesRanges'].end)
      return true
    if (key === 'numberOfFundingRounds' && end === maxNumOfFundingRounds) return true
    if (key === 'numberOfInvestorsRanges' && end === maxNumOfInvestors) return true
    return false
  }

  const getLabelForDataObject = (start: string, end: string, key: string) => {
    if (checkValueDefaultRangeObject(start, end, key)) return ''
    const valueStart = !start ? '...' : formatNumber(start)
    const valueEnd = !end ? '...' : formatNumber(end)

    return `${valueStart} - ${valueEnd}${
      checkMaxValueOfListKeyWithStartAndEnd(key, end) ? '+' : ''
    }`
  }

  const getLabelForDataString = (
    start: string | number | null,
    end: string | number | null,
    type: string
  ) => {
    const getValue = (value: string | number | null, type: string) => {
      return !value
        ? '...'
        : type === 'number'
        ? `$${formatAbbreviation(+value)}`
        : type === 'date'
        ? moment(value.toString()).format(FORMAT_DATE)
        : value
    }

    const valueStart = getValue(start, type)
    const valueEnd = getValue(end, type)

    if (Array.isArray(valueStart) && Array.isArray(valueEnd) && valueStart[0] === valueEnd[0])
      return valueStart
    if (valueStart == valueEnd) return `${valueStart}`
    return `${valueStart} - ${valueEnd}`
  }

  const getChildrenProductIdsById = useCallback(
    (idCurrent: number) => {
      const flatArray = flatMapDeep(productsTree, getMembers)
      const nodes = find(flatArray, ({ id }) => id === idCurrent)
      if (nodes) {
        return getChildrenIds(nodes?.children as ITreeData[])
      } else {
        return []
      }
    },
    [productsTree, getMembers, getChildrenIds]
  )

  const getChildrenTagIdsById = useCallback(
    (id: number) => {
      const tags = companyFilterData?.tags || []
      const tagGroups = tags?.find(e => e.tagGroupId === id)
      if (!tagGroups) return []
      return (tagGroups.tags || []).map(e => e?.tag_id || 0)
    },
    [companyFilterData]
  )

  const getChildrenValueChains = useCallback(
    (id: number) => {
      const valueChains = companyFilterData?.valueChains || []
      return valueChains.filter(e => e.level === 2 && e.parent_id === id).map(e => e.id)
    },
    [companyFilterData?.valueChains]
  )

  useEffect(() => {
    const itemsIsRemovedChildLevel = [
      {
        key: 'products',
        getChildrenIds: (id: number) => getChildrenProductIdsById(id),
        idsNotShows: [] as (string | number)[],
      },
      {
        key: 'tagGroupIds',
        getChildrenIds: (id: number) => getChildrenTagIdsById(id),
        idsNotShows: [] as number[],
      },
      {
        key: 'valueChains',
        getChildrenIds: (id: number) => getChildrenValueChains(id),
        idsNotShows: [] as number[],
      },
    ]

    itemsIsRemovedChildLevel.forEach(item => {
      const ids = filter[`${item.key}`] || []
      if (!ids.length) return
      ids.forEach((id: number) => {
        const childrenIds = item.getChildrenIds(id)
        if (!childrenIds.some(e => !ids?.includes(e))) {
          item.idsNotShows.push(...childrenIds)
        } else {
          // Remove Fintech || RegTech || InsurTech
          if (
            item.key === 'products' &&
            (id === TAXONOMY_ID.FINTECH_ID || TAXONOMY_ID.REGTECH_ID || TAXONOMY_ID.INSURANCE_ID)
          ) {
            item.idsNotShows.push(id)
          }
        }
      })
    })

    const obj = itemsIsRemovedChildLevel.reduce((current: any, item) => {
      current[item.key] = uniqBy(
        item.idsNotShows.map(e => +e),
        id => id
      )
      return current
    }, {})

    const fundingRound2sIds = companyFilterData?.fundingRound2s?.map(e => e.id) || []

    setIdsNotShow(prev => ({
      ...prev,
      ...obj,
      fundingRound2s: filter?.fundingRound1s?.includes(EXPAND_ROUND1_ID.EQUITY_FINANCING)
        ? fundingRound2sIds
        : [],
      latestFundingRound2s: filter?.latestFundingRound1s?.includes(
        EXPAND_ROUND1_ID.EQUITY_FINANCING
      )
        ? fundingRound2sIds
        : [],
    }))
  }, [
    filter,
    getChildrenProductIdsById,
    getChildrenTagIdsById,
    companyFilterData,
    history.location.pathname,
  ])

  const dealTypeNames = useMemo(() => {
    const fundingRound1s = data?.fundingRound1s || []
    const fundingRound2s = data?.fundingRound2s || []
    return [
      fundingRound1s[0]?.name,
      fundingRound1s[1]?.name,
      ...fundingRound2s?.map(e => e.name),
      fundingRound1s[2]?.name,
    ]
  }, [data?.fundingRound1s, data?.fundingRound2s])

  const orderList = (list: ChipItem[], listOrders: string[]) => {
    return list.sort((a, b) => {
      return listOrders.indexOf(a?.label) - listOrders.indexOf(b?.label)
    })
  }

  const tagsMapping = tagGroupsMapData({
    getFilterData: getFilterData?.getDealFilterData?.data,
    type: ETagGroupsType.Taxonomy,
  })

  useEffect(() => {
    if (!data || !filter) setChipsHeader([])
    else {
      const propsMap: Record<string, string> = {
        businessModelIds: 'businessModels',
        latestFundingRound1s: 'fundingRound1s',
        latestFundingRound2s: 'fundingRound2s',
        investorsTypes: 'acquirerTypes',
        leadInvestorTypes: 'acquirerTypes',
        leadInvestors: 'investors',
        tagGroupIds: 'tagGroups',
        distributionModelIds: 'distributionModels',
      }

      const allChips = Object.keys(filter).reduce((acc: ChipItem[], k: string) => {
        const key = k as keyof ICompanyDealFilter
        let value
        const valueWithFromAndTo = listKeyFromAndTo.find(e => e.keyFrom === key)
        const valueWithStartAndEnd = listKeyWithStartAndEnd.includes(key)
        const isKeyProduct = key === 'products'
        const isSelectedProductId = key === 'selectedProductId'
        const isSelectedBusinessLineId = key === 'selectedBusinessLineId'

        if (valueWithFromAndTo) {
          if (valueWithStartAndEnd) {
            value = {
              key: valueWithFromAndTo.keyFrom,
              label: getLabelForDataObject(
                filter[key]?.start,
                filter[key]?.end,
                valueWithFromAndTo.keyFrom
              ),
              value: filter[key],
            }
          } else {
            value = {
              key: valueWithFromAndTo.keyFrom,
              label: getLabelForDataString(
                filter[valueWithFromAndTo.keyFrom],
                filter[valueWithFromAndTo.keyTo],
                CHIP_TYPE_DATE.includes(key)
                  ? 'date'
                  : CHIP_TYPE_YEAR.includes(key)
                  ? 'year'
                  : 'number'
              ),
              value: {
                start: filter[valueWithFromAndTo.keyFrom],
                end: filter[valueWithFromAndTo.keyTo],
              },
            }
          }
        } else {
          const keysIsRemovedChildLevel = Object.keys(idsNotShows) as Array<keyof IdsNotShowsType>
          if (keysIsRemovedChildLevel.includes(key as keyof IdsNotShowsType)) {
            value = filter[key]?.filter(
              (e: number | string) => !idsNotShows[key as keyof IdsNotShowsType].includes(+e)
            )
          } else {
            value = filter[key]
          }
        }

        if (
          !CHIP_HIDE_LISTVIEW[key] ||
          !value ||
          !data ||
          listKeyFromAndTo.map(e => e.keyTo).includes(key) ||
          (valueWithFromAndTo &&
            !filter[valueWithFromAndTo.keyFrom] &&
            !filter[valueWithFromAndTo.keyTo])
        )
          return acc

        // TODO: replace 'any'
        const list = (data[(propsMap[k] || k) as keyof GetDealFilterDataResult_getDealFilterData] ||
          []) as Array<any>

        const getValue = (val: string | number, key?: string) => {
          if (isKeyProduct && filter.category === 'all') {
            switch (val) {
              case TAXONOMY_ID.REGTECH_ID:
                return 'RegTech'
              case TAXONOMY_ID.FINTECH_ID:
                return 'Fintech'
              case TAXONOMY_ID.INSURANCE_ID:
                return 'InsurTech'
              default:
                break
            }
          }

          const chipName = list?.find((item: IdName) => String(item.id) == String(val))?.name
          if (chipName) {
            return chipName
          }
          const item = list?.find(item => item == val)
          if (item) {
            return item
          }
          return ''
        }

        const getValueCapitalize = (value: string, key: string) => {
          switch (value) {
            case InternalDbCompanyTypes.fintech:
              return 'FinTech'
            case InternalDbCompanyTypes.regtech:
              return 'RegTech'
            case InternalDbCompanyTypes.insurtech:
              return 'InsurTech'
            default:
              return value
          }
        }

        const tmp = Array.isArray(value)
          ? (value as Array<string | number>).map((d: string | number) => ({
              label: getValue(d, key),
              key,
              value: d as ICompanyDealFilter[keyof ICompanyDealFilter],
            }))
          : isObject(value)
          ? {
              label: '',
              ...value,
            }
          : {
              label:
                isSelectedProductId || isSelectedBusinessLineId
                  ? getValue(value, key)
                  : getValueCapitalize(value, key),
              key,
              value,
            }

        return Array.isArray(tmp) ? [...acc, ...tmp] : [...acc, tmp]
      }, [])

      const displayChips: ChipItem[] = allChips
        .filter(item => item.label !== 'All' && item.label !== 'all' && !!item.label)
        .map(e => {
          if (e.key === 'fundingRound1s') {
            if (e.value === EXPAND_ROUND1_ID.MA)
              return {
                ...e,
                key: HAS_ACQUIRED_KEY,
                label: HAS_ACQUIRED_LABEL,
              }
            if (e.value === EXPAND_ROUND1_ID.IPO)
              return {
                ...e,
                key: HAS_IPO_KEY,
                label: HAS_IPO_LABEL,
              }
          }
          return e
        })

      const getData = (key: keyof ICompanyDealFilter) => {
        return displayChips.filter(e => e.key === key) || []
      }

      const chips = LIST_CHIP_ORDERS.map(e => {
        const isKeyArray = Array.isArray(e.key)
        let names = []
        if (isKeyArray) {
          names = [...dealTypeNames]
        } else {
          const k = e.key as string
          if (e.key === 'products') {
            names = getFlatArrayFromTree(productsTree as ITreeData[])?.map(e => e.name) || []
          } else if (e.key === 'valueChains') {
            const valueChains = companyFilterData?.valueChains || []
            const valueChainsL2 = valueChains.filter(e => e.level === 2)
            valueChains.forEach(e => {
              if (e.level !== 1) return
              names.push(e.name)
              valueChainsL2.forEach(el => {
                if (el.parent_id === e.id) {
                  names.push(el.name)
                }
              })
            })
          } else if (e.key === 'tagGroupIds') {
            names = getFlatArrayFromTree(tagsMapping.tagsTree)?.map(e => e.name) || []
          } else if (SPECIAL_CHIPS.includes(k)) {
            names = (data as any)[propsMap[k] || k]
          } else names = (data as any)[propsMap[k] || k]?.map((k: any) => k.name) || []
        }

        return {
          key: e.key || '',
          listNames: names,
          chips: isKeyArray
            ? [
                ...getData((e.key as (keyof ICompanyDealFilter)[])[0]),
                ...getData((e.key as (keyof ICompanyDealFilter)[])[1]),
              ]
            : getData(e.key as keyof ICompanyDealFilter),
          hasOrder: e.hasOrder,
        }
      })

      setChipsHeader(
        chips.flatMap(e => {
          return e.hasOrder ? orderList(e?.chips, e?.listNames) : e.chips
        })
      )
    }
  }, [filter, data, idsNotShows])

  const roundRelationship = React.useMemo(() => {
    if (!data) return {}
    const result: Record<string, Array<string>> = {}
    const { fundingRound1s, fundingRound2s } = data
    fundingRound1s?.forEach(v => {
      if (!v) return
      if (v.name === 'Equity Financing') result[v.name] = fundingRound2s?.map(i => i.name) || []
      else result[v.name] = []
    })
    return result
  }, [data])

  const onDelete = (c: ChipItem) => {
    const valueObject = listKeyFromAndTo.find(e => e.keyFrom == c.key)

    if (valueObject) {
      if (listKeyWithStartAndEnd.includes(c.key)) {
        let newFilter: any = {}
        if (c.key === 'numOfEmployeesRanges') {
          newFilter = initialAnalysisFilter['numOfEmployeesRanges']
        }
        if (c.key === 'numberOfFundingRounds') {
          newFilter = {
            start: '0',
            end: String(companyFilterData?.maxNumOfFundingRounds || DEFAULT_DATA_MAX),
          }
        }
        if (c.key === 'numberOfInvestorsRanges') {
          newFilter = {
            start: '0',
            end: String(companyFilterData?.maxNumOfInvestors || DEFAULT_DATA_MAX),
          }
        }
        setFilter({ [c.key]: newFilter })
      } else {
        const newFilter: any = {
          [valueObject.keyFrom]:
            initialAnalysisFilter[valueObject.keyFrom as keyof typeof initialAnalysisFilter],
          [valueObject.keyTo]:
            initialAnalysisFilter[valueObject.keyTo as keyof typeof initialAnalysisFilter],
        }
        setFilter(newFilter)
      }
    } else {
      const isKeyIpoOrAcquired = c.key === HAS_IPO_KEY || c.key === HAS_ACQUIRED_KEY
      const key = isKeyIpoOrAcquired ? 'fundingRound1s' : c.key
      const fv = filter[key]

      const newValue = Array.isArray(fv)
        ? (fv as Array<string | number>)?.filter(i => i !== c.value)
        : null

      const newFilter: any = {
        [key]: Array.isArray(newValue) ? (newValue?.length ? newValue : null) : null,
      }

      if (c.key === 'modeName') {
        newFilter.smartCluster = initSmartCluster
        newFilter.orderByCompany = [{ field: FCT_SCORE_OVERALL_KEY, direction: 'desc' }]
      }

      if (c.key === 'products' && c.value) {
        const childrenIds = getChildrenProductIdsById(+c.value)
        let idsRemove = [...childrenIds, c.value]

        // Remove product lv1
        LIST_IDS_PRODUCT_LV1.forEach(e => {
          const childrenIds = getChildrenProductIdsById(e)
          if (!childrenIds.every(e => filter?.products?.includes(e))) {
            idsRemove.push(e)
          }
        })

        newFilter[c.key] = newFilter[c.key]?.filter((e: string | number) => !idsRemove.includes(e))
        newFilter.selectedProductId = null
      }

      if (c.key === 'tagGroupIds') {
        const tagGroups = companyFilterData.tags?.find(e => e.tagGroupId === c.value)
        if (tagGroups) {
          newFilter[c.key] = newFilter[c.key]?.filter(
            (i: string | number) => !tagGroups?.tags?.some(e => e?.tag_id == i)
          )
        }
      }

      if (c.key === 'valueChains' && c?.value) {
        const valueChains = getChildrenValueChains(+c.value)
        newFilter[c.key] = newFilter[c.key]?.filter(
          (i: string | number) => !valueChains?.some(e => e === i)
        )
      }

      if (c.key === 'fundingRound1s') {
        newFilter.fundingRound2s =
          c.value === EXPAND_ROUND1_ID.EQUITY_FINANCING ? null : filter.fundingRound2s
      }
      if (c.key === 'fundingRound2s') {
        const parent = findKey(roundRelationship, arr => arr.includes((c.value || '') as string))
        if (
          parent &&
          roundRelationship[parent].filter(i => !(newFilter[c.key] as Array<string>)?.includes(i))
            .length > 0
        ) {
          const newFr1 = filter.fundingRound1s?.filter((i: string) => i !== parent)
          newFilter.fundingRound1s = newFr1?.length ? newFr1 : null
        }
      }

      if (c.key === 'latestFundingRound1s') {
        newFilter.latestFundingRound2s =
          c.value === EXPAND_ROUND1_ID.EQUITY_FINANCING ? null : filter.latestFundingRound2s
      }
      if (c.key === 'latestFundingRound2s') {
        const parent = findKey(roundRelationship, arr => arr.includes((c.value || '') as string))

        if (
          parent &&
          roundRelationship[parent].filter(i => !(newFilter[c.key] as Array<string>)?.includes(i))
            .length > 0
        ) {
          const newFr1 = filter.latestFundingRound1s?.filter((i: string) => i !== parent)
          newFilter.latestFundingRound1s = newFr1?.length ? newFr1 : null
        }
      }

      const resetBreadcrumbs = () => {
        setBreadcrumbsData([
          {
            id: 0,
            name: '',
          },
        ])
      }

      if (c.key === 'category') {
        newFilter.category = InternalDbCompanyTypes.all
        newFilter.viewBy = GlobalFilterViewBy.product
        newFilter.products = []
        newFilter.businessLines = []
        newFilter.selectedProductId = null
        newFilter.selectedBusinessLineId = 0
        newFilter.valueChains = []
        newFilter.operatingModels = []
        resetBreadcrumbs()
      }

      if (c.key === 'businessLines') {
        newFilter.selectedBusinessLineId = null
        newFilter.selectedProductId = null
        resetBreadcrumbs()
      }

      if (c.key === 'valueChains' || c.key === 'operatingModels') {
        newFilter.selectedBusinessLineId = null
        resetBreadcrumbs()
      }

      setAnalysisFilter(newFilter)
      setCompanyFilter(newFilter)
    }
  }

  const resetWatchList = () => {
    setWatchListId(null)
    setWatchListName(null)
    useResetFilter(setAnalysisFilter, initialAnalysisFilter, companyFilterData)
    useResetFilter(setCompanyFilter, initCompanyDealFilter, companyFilterData)
  }

  const handleResetFilter = () => {
    if (watchListId && watchlistType == EWatchListType.filterConditions) {
      setCompanyFilter(watchlistFilter)
      return
    }
    setSubFilter(initSubFilter)
    useResetFilter(setAnalysisFilter, initialAnalysisFilter, companyFilterData)
    useResetFilter(setCompanyFilter, initCompanyDealFilter, companyFilterData)
  }

  const renderCompareChip = (valueStr: string | null) => {
    return (
      <span className={classes.moreText}>
        <strong>Compare: </strong>
        {20 < (valueStr?.length || 0) ? valueStr?.slice(0, 20) + '..., ' : valueStr + ', '}
      </span>
    )
  }

  const getTooltip = (key: keyof ICompanyDealFilter) => {
    return key in TitleTooltip ? TitleTooltip[key as keyof typeof TitleTooltip] : ''
  }

  const chipHeader = chips.map(e => ({
    ...e,
    title: getTooltip(e.key),
    onDelete: () => onDelete(e),
  }))

  const chipList = [
    ...(!watchListName
      ? []
      : [
          {
            label: (
              <p className={classes.moreText}>
                <strong>Watchlist: </strong>{' '}
                {watchListName.length > 10 ? `${watchListName.slice(0, 10)}...` : watchListName}
              </p>
            ),
            title: watchListName,
            onDelete: () => resetWatchList(),
            value: '',
          },
        ]),
    ...chipHeader
      .filter(e => e.key === 'modeName')
      .map(e => {
        const smartClusterLabel = `${e.label} (${MIN_SMART_CLUSTER_DISTANCE}${
          filter?.smartCluster?.distance !== MIN_SMART_CLUSTER_DISTANCE
            ? ` - ${filter?.smartCluster?.distance})`
            : ')'
        }`
        return { ...e, label: smartClusterLabel, title: 'Smart Cluster Distance' }
      }),
    ...(!!subFilter.currentCompanyName
      ? [
          {
            label: (
              <p>
                {renderCompareChip(subFilter.currentCompanyName)}
                {COMPARE_TYPE.get(subFilter.compareType)}
              </p>
            ),
            title: subFilter.currentCompanyName,
            onDelete: () => {
              setSubFilter(initSubFilter)
              useResetFilter(setAnalysisFilter, initialAnalysisFilter, companyFilterData)
              useResetFilter(setCompanyFilter, initCompanyDealFilter, companyFilterData)
            },
            value: '',
          },
        ]
      : []),
    ...chipHeader.filter(e => e.key !== 'modeName'),
  ]

  return (
    <ChipList
      data={[...chipList]}
      filter={filter}
      handleResetFilter={handleResetFilter}
      hasButtonReset={hasButtonReset}
    />
  )
}

export default ChipsHeader

const ChipList = ({
  data,
  filter,
  hasButtonReset,
  handleResetFilter,
}: {
  data: Array<{
    label: string | ReactElement
    onDelete(): void
    title: string
    value: IAnalysisFilter[keyof IAnalysisFilter] | ICompanyDealFilter[keyof ICompanyDealFilter]
  }>
  filter: any
  hasButtonReset?: boolean
  handleResetFilter: () => void
}) => {
  const classes = useStyles()
  const containerRef = useRef(null)

  const { limit, accumulatedWidth } = useLimitChip(containerRef, data.length)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [subFilter, _____] = useSubFilter()

  useEffect(() => {
    // Remove anchorEl
    if (!limit) setAnchorEl(null)
  }, [limit])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const hiddenChips = !!limit && limit > 0 ? data.slice(limit) : []

  const open = Boolean(anchorEl)
  const isShowButtonReset = Boolean(hasButtonReset && data.length)

  const disableBtnDelete = (
    title: string,
    value: IAnalysisFilter[keyof IAnalysisFilter] | ICompanyDealFilter[keyof ICompanyDealFilter]
  ) => {
    if (subFilter.currentCompanyName) {
      if (
        title === TitleTooltip.category ||
        (title === TitleTooltip.products && subFilter?.product === value) ||
        (title === TitleTooltip.businessLines && subFilter?.businessLines === value) ||
        (title === TitleTooltip.valueChains && subFilter?.valueChains === value) ||
        (title === TitleTooltip.operatingModels && subFilter?.operatingModel === value)
      ) {
        return false
      }
    }
    return true
  }

  return (
    <Flex style={{ alignItems: 'center' }}>
      <Flex
        gap={8}
        className={clsx({
          [classes.wrapperChip]: isShowButtonReset,
        })}
      >
        <div
          className={classes.chipList}
          ref={containerRef}
          style={{
            width: limit && limit >= 1 ? accumulatedWidth[limit - 1] : 'auto',
          }}
        >
          {data.map((c, idx) => (
            <Fragment key={idx}>
              <StyledChipItem
                {...c}
                filter={filter}
                disabled={!!limit && idx >= limit}
                style={{
                  opacity: limit && idx >= limit ? 0 : 1,
                  pointerEvents: limit && idx >= limit ? 'none' : 'auto',
                }}
                hasOnDelete={disableBtnDelete(c.title, c.value)}
              />
            </Fragment>
          ))}
        </div>
        {!!hiddenChips.length && (
          <FCTButton
            className={clsx({ [classes.button]: true, active: open })}
            variant="outlined"
            color="default"
            onClick={handleClick}
            style={{ marginLeft: -5 }}
          >
            +{hiddenChips.length}
          </FCTButton>
        )}
      </Flex>
      {!!hiddenChips.length && (
        <Popover
          id={open ? 'chipsHeader_chipList-popover' : undefined}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Flex flexWrap="wrap" className={classes.missingChip} gap={8}>
            {hiddenChips.map((c, idx) => (
              <Fragment key={idx}>
                <StyledChipItem
                  {...c}
                  filter={filter}
                  hasOnDelete={disableBtnDelete(c.title, c.value)}
                />
              </Fragment>
            ))}
            <div className={classes.hideButton} onClick={handleClose}>
              Hide
            </div>
          </Flex>
        </Popover>
      )}
      {isShowButtonReset && (
        <FCTButton
          variant="outlined"
          className={classes.buttonReset}
          onClick={handleResetFilter}
          style={{ marginLeft: data.length ? 'unset' : 8 }}
          startIcon={
            <FCTIcon className={classes.buttonIcon} name="refresh" width={12} height={12} />
          }
        >
          Reset
        </FCTButton>
      )}
    </Flex>
  )
}

const useLimitChip = (containerRef: RefObject<HTMLDivElement>, watchDataLength: number) => {
  const [limit, setLimit] = useState<number>()
  const [accumulatedWidth, setAccumulatedWidth] = useState<number[]>([])

  const { width } = useWindowSize()
  const history = useHistory()
  const theme = useTheme()

  const PADDING_LEFT_CONTAINER = 150
  const PADDING_RIGHT_CONTAINER = 150
  const widthChips = width - (PADDING_LEFT_CONTAINER + PADDING_RIGHT_CONTAINER)
  const isSmartSearchPage = history.location.pathname.includes('smart-search')

  const CONTAINER_WIDTH = useMemo(
    () =>
      width < theme.breakpoints.values.sm //600px
        ? isSmartSearchPage
          ? widthChips
          : 384
        : width < theme.breakpoints.values.md //900px
        ? isSmartSearchPage
          ? widthChips
          : 450
        : width < theme.breakpoints.values.lg // 1200px
        ? isSmartSearchPage
          ? widthChips
          : 535
        : isSmartSearchPage
        ? widthChips
        : 800,
    [width, isSmartSearchPage]
  )

  const getContainerWidth = (_containerRef: RefObject<HTMLDivElement>) => {
    const PADDING_X = 16
    const INPUT_MIN_WIDTH = 0
    return CONTAINER_WIDTH - PADDING_X - INPUT_MIN_WIDTH
  }

  const getChipsAccumulatedWidth = (containerRef: RefObject<HTMLDivElement>) => {
    const GAP = 8
    return Array.from(containerRef?.current?.children || [])
      ?.map(item => item.getBoundingClientRect().width + GAP)
      ?.reduce((acc: number[], cur) => {
        const v = acc.length ? acc[acc.length - 1] + cur : cur
        return [...acc, v]
      }, [])
  }

  const getLimitTags = (containerRef: RefObject<HTMLDivElement>) => {
    const containerWidth = getContainerWidth(containerRef)
    const chipsAccumulatedWidth = getChipsAccumulatedWidth(containerRef)
    setAccumulatedWidth(chipsAccumulatedWidth)

    if (containerWidth < chipsAccumulatedWidth[0]) return 1

    const overflowIndex = chipsAccumulatedWidth.findIndex(item => item > containerWidth)

    return overflowIndex > -1 ? overflowIndex : undefined
  }

  useLayoutEffect(() => {
    if (width > 0) setLimit(prev => getLimitTags(containerRef) || prev)
  }, [watchDataLength, width, CONTAINER_WIDTH])

  return { limit, setLimit, accumulatedWidth }
}

const TAXONOMY_ID = {
  INSURANCE_ID: -1,
  FINTECH_ID: -2,
  REGTECH_ID: -3,
  SUPPORT_ID: 11,
}

const LIST_IDS_PRODUCT_LV1 = [
  TAXONOMY_ID.REGTECH_ID,
  TAXONOMY_ID.INSURANCE_ID,
  TAXONOMY_ID.FINTECH_ID,
]
